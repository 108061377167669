import { Injectable } from '@angular/core';

export enum StateType {
    PROVIDER_LIST_STATE = 'PROVIDER_LIST_STATE',
    CUSTOMER_LIST_STATE = 'CUSTOMER_LIST_STATE',

    PROVIDER_DETAIL_STATE = 'PROVIDER_DETAIL_STATE',
    PROJECT_LIST_STATE = 'PROJECT_LIST_STATE',
    PROJECT_DETAIL_STATE = 'PROJECT_DETAIL_STATE',
    BUDGET_LIST_STATE = 'BUDGET_LIST_STATE',
    BILL_LIST_STATE = 'BILL_LIST_STATE',
    BUDGET_DETAIL_STATE = 'BUDGET_DETAIL_STATE',
    EVENT_HISTORIC_LIST_STATE = 'EVENT_HISTORIC_LIST_STATE',
    CUSTOMER_DETAIL_STATE = 'CUSTOMER_DETAIL_STATE',
    INVENTORY_LIST_STATE = 'INVENTORY_LIST_STATE',
    INVENTORY_DETAIL_STATE = 'INVENTORY_DETAIL_STATE',
    ARTIST_LIST_STATE = 'ARTIST_LIST_STATE',
    COMPANY_LIST_STATE = 'COMPANY_LIST_STATE',
    CONTACT_LIST_STATE = 'CONTACT_LIST_STATE',
    USER_LIST_STATE = 'USER_LIST_STATE',
    ENCLOSURE_LIST_STATE = 'ENCLOSURE_LIST_STATE',
    EVENT_CALENDAR_STATE = 'EVENT_CALENDAR_STATE',
    PAYMENT_CALENDAR_STATE = 'PAYMENT_CALENDAR_STATE',
    ARTIST_REPORT_STATE = 'ARTIST_REPORT_STATE',
    PAYMENT_REPORT_STATE = 'PAYMENT_REPORT_STATE',
    EVENT_MAP_STATE = 'EVENT_MAP_STATE'
}

export interface State {
    type: StateType | string;
    lastIdClicked?: any;
    pageIndex?: any;
}

@Injectable({
    providedIn: 'root'
})
export class StateService {
    private states: State[] = [];

    setState(state: State) {
        const index = this.states.findIndex((f) => f.type === state.type);
        if (index > -1) {
            this.states[index] = state;
        } else {
            this.states.push(state);
        }
        localStorage.setItem(state.type, JSON.stringify(state));
    }

    getState(type: StateType): State | undefined {
        const storageState = localStorage.getItem(type) ? JSON.parse(localStorage.getItem(type)) : null;
        return this.states.find((state) => state.type === type) ?? storageState ?? null;
    }

    removeState(type: StateType) {
        this.states = this.states.filter((state) => state.type !== type);
        localStorage.removeItem(type);
    }

    clearState() {
        this.states = [];
        Object.keys(StateType).forEach((key) => {
            localStorage.removeItem(StateType[key]);
        });
    }
}
