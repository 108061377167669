<div class="modal-container">
    <div class="modal-title">
        {{ 'module.calendar.legend' | transloco }}
    </div>
    <div mat-dialog-content class="modal-content-container">
        <div class="legend-container">
            <div class="legend-item" *ngFor="let info of legend" [ngClass]="info.color" [ngClass]="info.class ? info.class : ''"
                [ngStyle]="info.color ? {'background-color': info.color} : {}">
                <div class="legend-text">
                    {{ info.label }}
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="justify-center">
            <button color="warn" mat-raised-button type="button" (click)="close()">
                {{ "common.close" | transloco }}
            </button>
        </div>
    </div>
</div>