<full-calendar #fullCalendar [options]="calendarOptions" [events]="events">
    <ng-template #eventContent let-arg>
        <div class="fc-event-title-container" title="{{ arg.event.extendedProps.tooltip }}" [id]="'event-id-' + arg.event.id">
            <div class="fc-event-title">
                <span class="calendar-event" [ngClass]="arg.event.extendedProps.classNames"
                    [ngStyle]="byColor ? getBackgroundStyle(arg.event.extendedProps.color) : {}">
                    <span class="leading-relaxed whitespace-nowrap" [ngStyle]="byColor ? getColorStyle(arg.event.extendedProps.color) : {}">
                        {{ arg.event.title }}
                    </span>
                </span>
            </div>
        </div>
    </ng-template>
</full-calendar>
