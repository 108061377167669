import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const calendarsItems: FuseNavigationItem = {
    id      : 'calendars',
    title   : 'menu.calendars.title',
    type    : 'group',
    icon    : 'heroicons_outline:home',
    children: [
        {
            id: 'activities.calendars',
            title: 'module.project.menu',
            type: 'basic',
            icon: 'heroicons_outline:calendar',
            link: '/calendars/project-calendar'
        },
        {
            id: 'activities.calendars',
            title: 'module.bill.menu',
            type: 'basic',
            icon: 'heroicons_outline:calendar',
            link: '/calendars/bill-calendar'
        }
    ]
}
