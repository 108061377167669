import { Injectable } from '@angular/core';

export enum FilterType {
    PROVIDER_LIST_FILTER = 'PROVIDER_LIST_FILTER',
    INVENTORY_LIST_FILTER = 'INVENTORY_LIST_FILTER',
    BUDGET_LIST_FILTER = 'BUDGET_LIST_FILTER',
    BILL_LIST_FILTER = 'BILL_LIST_FILTER',
    ARTIST_LIST_FILTER = 'ARTIST_LIST_FILTER',
    COMPANY_LIST_FILTER = 'COMPANY_LIST_FILTER',
    CONTACT_LIST_FILTER = 'CONTACT_LIST_FILTER',
    USER_LIST_FILTER = 'USER_LIST_FILTER',
    ENCLOSURE_LIST_FILTER = 'ENCLOSURE_LIST_FILTER',
    EVENT_CALENDAR_FILTER = 'EVENT_CALENDAR_FILTER',
    PAYMENT_CALENDAR_FILTER = 'PAYMENT_CALENDAR_FILTER',
    ARTIST_REPORT_FILTER = 'ARTIST_REPORT_FILTER',
    PAYMENT_REPORT_FILTER = 'PAYMENT_REPORT_FILTER',
    ARTIST_REPORT_EXPORT_CONFIG = 'ARTIST_REPORT_EXPORT_CONFIG',
    PAYMENT_REPORT_EXPORT_CONFIG = 'PAYMENT_REPORT_EXPORT_CONFIG',
    EVENT_MAP_FILTER = 'EVENT_MAP_FILTER',
    FILE_MANAGER_FILTER = 'FILE_MANAGER_FILTER'
}

export interface Filter {
    type: FilterType | string;
    payload: any;
}

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private filters: Filter[] = [];

    setFilter(filter: Filter) {
        const index = this.filters.findIndex((f) => f.type === filter.type);
        if (index > -1) {
            this.filters[index] = filter;
        } else {
            this.filters.push(filter);
        }
        localStorage.setItem(filter.type, JSON.stringify(filter));
    }

    getFilter(type: FilterType | string): Filter | undefined {
        const storageFilter = localStorage.getItem(type) ? JSON.parse(localStorage.getItem(type)) : null;
        return this.filters.find((filter) => filter.type === type) ?? storageFilter ?? null;
    }

    removeFilter(type: FilterType | string) {
        this.filters = this.filters.filter((filter) => filter.type !== type);
        localStorage.removeItem(type);
    }

    clearFilters() {
        this.filters = [];
        Object.keys(FilterType).forEach((key) => {
            localStorage.removeItem(FilterType[key]);
        });
    }
}
