import {
    AfterContentChecked,
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import { ModalSizes } from '../../../core/constants/modal-sizes.enum';
import { FormatUtils } from '../../utils/format.util';
import { GeneralUtils } from '../../utils/general.util';
import { LegendModalComponent } from './legend-modal/legend-modal.component';
import { StateService } from '../../../core/services';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarService } from '../../../modules/calendar/services/calendar.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements AfterViewInit, OnChanges, AfterContentChecked {
    @Input('events') events: any[] = [];
    @Input('byColor') byColor: boolean = true;
    @Input('stateType') stateType: string = null;
    @Input('pageIndex') pageIndex: any = null;
    @Input('lastIdClicked') lastIdClicked: any = null;
    @Input('legend') legend: any[] = [];
    @Input('legendStatus') legendStatus: any[] = [];
    @ViewChild('fullCalendar') fullCalendar: any;

    calendarOptions: CalendarOptions = null;
    openId: string = null;

    constructor(
        private readonly _translocoService: TranslocoService,
        private matDialog: MatDialog,
        private readonly _formatUtils: FormatUtils,
        private readonly _generalUtils: GeneralUtils,
        private readonly _stateService: StateService,
    ) {
        this.loadOptions();
        this._translocoService.langChanges$.subscribe((lang) => (this.calendarOptions.locale = lang));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.events && !changes.events.firstChange && this.lastIdClicked) {
            const open = this.events.find((event) => event.id === this.lastIdClicked.toString());
            if (open) this.openId = open.id;
        }
    }

    ngAfterViewInit(): void {
        // go to the month preselected
        if (this.pageIndex) {
            this.fullCalendar.getApi().gotoDate(this.pageIndex);
        }
    }

    ngAfterContentChecked(): void {
        if (this.openId !== null) {
            const element1 = document.getElementById(`event-id-${this.openId}`);
            if (element1) {
                element1.click();
                this.openId = null;
                this._stateService.clearState();
            }
        }
    }

    loadOptions(): void {
        this.calendarOptions = {
            initialView: 'dayGridMonth',
            eventClick: this.handleEventClick.bind(this),
            dateClick: this.handleDateClick.bind(this),
            plugins: [dayGridPlugin, multiMonthPlugin, listPlugin, interactionPlugin],
            locale: 'es',
            firstDay: 1, // Moday
            dayMaxEvents: false,
            dayMaxEventRows: 3,
            selectMirror: true,
            weekends: true,
            editable: false,
            selectable: true,
            contentHeight: 'auto',
            customButtons: {
                legendButton: {
                    text: this._translocoService.translate('module.calendar.legend'),
                    click: () => {
                        this.showLegend();
                    }
                }
            },
            headerToolbar: {
                left: 'prev,next today legendButton',
                center: 'title',
                right: 'dayGridMonth,multiMonth,listYear'
            },
            views: {
                dayGridMonth: {
                    type: 'dayGridMonth',
                    buttonText: this._translocoService.translate('module.calendar.oneMonth')
                },
                multiMonth: {
                    type: 'multiMonth',
                    duration: { months: 6 },
                    buttonText: this._translocoService.translate('module.calendar.sixMonths')
                },
                listYear: {
                    type: 'listYear',
                    buttonText: this._translocoService.translate('module.calendar.list')
                }
            }
        };
    }
    handleDateClick(arg: any) {
        const today = new Date().toISOString().split('T')[0];
        const currentUrl = window.location.pathname;
        /* if (currentUrl === '/calendars/event-calendar' && arg.dateStr >= today) {
            this.openModalCreateEvent(arg.dateStr);
        } */
    }

    handleEventClick(arg: EventClickArg) {
        this.openModal(arg.event.extendedProps);
    }

    openModal(item: any) {
        const dialogRef = this.matDialog.open(CalendarModalComponent, {
            width: ModalSizes.SMALL_WIDTH,
            data: {
                item,
                stateType: this.stateType,
                pageIndex: this._formatUtils.formatAmericanDate(this.fullCalendar.getApi().getDate())
            }
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }

    getBackgroundStyle(selectedColor: string): any {
        if (!this.byColor) {
            return {};
        }

        if (!selectedColor) {
            return { 'background-color': '#5e5c64' };
        } else {
            return { 'background-color': selectedColor };
        }
    }

    getColorStyle(selectedColor: string): any {
        if (!this.byColor) {
            return {};
        }

        if (!selectedColor) {
            return { color: 'white' };
        } else {
            return { color: this._generalUtils.darkenHexColor(selectedColor, 50) };
        }
    }

    showLegend(): void {
        const dialogRef = this.matDialog.open(LegendModalComponent, {
            width: ModalSizes.SMALL_WIDTH,
            data: {
                legend: this.byColor ? this.legend : this.legendStatus
            }
        });

        dialogRef.afterClosed().subscribe(() => {});
    }
}
