import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Legend {
    label: string;
    color?: string;
    class?: string[];
}

@Component({
    selector: 'app-legend-modal',
    templateUrl: './legend-modal.component.html',
    styleUrls: ['./legend-modal.component.scss']
})
export class LegendModalComponent {
    legend: Legend[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LegendModalComponent>) {
        this.legend = data.legend;
    }

    close() {
        this.dialogRef.close();
    }
}
