import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'input-date-check',
    templateUrl: './input-date-check.component.html',
    styleUrls: ['./input-date-check.component.scss']
})
export class InputDateCheckComponent implements OnInit {
    @Input() formGroup: UntypedFormGroup;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() name: string;
    @Input() icon: string = '';
    @Input() formControlCheck: string;
    @Input() value: string = '';
    @Input() valueCheck: string = '';
    @Input() action($event: any): void {}

    ngOnInit(): void {
      this.formGroup.addControl(this.name, new FormControl(this.value));
      this.formGroup.addControl(this.formControlCheck, new FormControl(this.valueCheck));
  }
}
