import { Injectable } from '@angular/core';
import { BaseCoreService } from './base-core.service';
import { HttpClient } from '@angular/common/http';
import { Order } from '../interfaces/order.interface';
import { Pagination } from '../interfaces/pagination.interface';
import { Observable } from 'rxjs';
import { Project } from 'app/modules/project/models/project.model';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectCoreService extends BaseCoreService<Project> {
    constructor(_httpClient: HttpClient, _userService: UserService) {
        super('v1/project', _httpClient, _userService);
    }

    findIn(
        filter: Project,
        page: number = 1,
        limit: number = 10,
        order: Order = { column: 'name', typeOrder: 'ASC' }
    ): Observable<Pagination<Project>> {
        return super.findIn(filter, page, limit, order);
    }
}
