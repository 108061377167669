import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const ownerItem: FuseNavigationItem = {
    id      : 'owner',
    title   : 'menu.admin.title',
    type    : 'group',
    icon    : 'mat_outline:settings',
    children:[
        {
            id   : 'settings.admin',
            title: 'module.defaults.menu',
            type : 'basic',
            icon : 'heroicons_outline:adjustments',
            link : '/defaults'
        },
        {
            id   : 'settings.admin',
            title: 'module.settings.menu',
            type : 'basic',
            icon : 'heroicons_outline:cog',
            link : '/settings'
        },
        {
            id   : 'settings.admin',
            title: 'module.user.menu',
            type : 'basic',
            icon : 'heroicons_outline:user-circle',
            link : '/users'
        },
        {
            id   : 'settings.admin',
            title: 'module.security.menu',
            type : 'basic',
            icon : 'heroicons_outline:shield-check',
            link : '/security'
        },
        {
            id   : 'settings.admin',
            title: 'module.automations.menu',
            type : 'basic',
            icon : 'heroicons_outline:light-bulb',
            link : '/automations'
        },
        {
            id   : 'settings.admin',
            title: 'module.integrations.menu',
            type : 'basic',
            icon : 'heroicons_outline:puzzle',
            link : '/integrations'
        }
    ]
};
