import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Address } from '../interfaces/address.interface';
import { map, take } from 'rxjs';
import { UriUtils } from '../../shared/utils/uri.util';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    private url = 'v1/address';

    constructor(private _httpClient: HttpClient) {}

    getProvinces() {
        return this._httpClient
            .get<Address[]>(UriUtils.buildUrl(this.url, 'provinces'))
            .pipe(take(1))
            .pipe(
                map((provinces: Address[]) => {
                    return provinces.sort((a, b) => a.label.localeCompare(b.label));
                })
            );
    }

    getCommunities() {
        return this._httpClient
            .get<Address[]>(UriUtils.buildUrl(this.url, 'communities'))
            .pipe(take(1))
            .pipe(
                map((communities: Address[]) => {
                    return communities.sort((a, b) => a.label.localeCompare(b.label));
                })
            );
    }

    getByCode(list: Address[], code: string) {
        return list.find((item) => item.code === code);
    }

    getByLabel(list: Address[], label: string) {
        return list.find((item) => item.label.toLowerCase() === label.toLowerCase()) ?? null;
    }

    getByParentCode(list: Address[], parentCode: string) {
        return list.find((item) => item.parent_code === parentCode);
    }
}
