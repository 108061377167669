import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const mapsItems: FuseNavigationItem = {
    id      : 'maps',
    title   : 'menu.maps.title',
    type    : 'group',
    icon    : 'heroicons_outline:home',
    children: [
        {
            id: 'activities.reports',
            title: 'module.project.menu',
            type: 'basic',
            icon: 'heroicons_outline:map',
            link: '/project/location-map'
        }
    ]
}
