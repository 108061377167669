import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UriUtils } from '../../shared/utils/uri.util';
import { take } from 'rxjs';
import { Mailer } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class MailerService {
    private url = 'v1/mailer';

    constructor(private _httpClient: HttpClient) {}

    sendMail(body: Mailer) {
        return this._httpClient.post<Mailer[]>(UriUtils.buildUrl(this.url), body).pipe(take(1));
    }
}
