<div [formGroup]="formGroup">
    <mat-form-field class="mat-form-field-container">
        <mat-label>{{ label }}</mat-label>
        <input matInput [formControlName]="name" [placeholder]="placeholder" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-checkbox [formControlName]="formControlCheck" matPrefix></mat-checkbox>
        <mat-error *ngIf="formGroup.get(name).hasError('required')">
            {{ "common.error.required" | transloco }}
        </mat-error>
    </mat-form-field>
</div>