import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const reportsItems: FuseNavigationItem = {
    id      : 'reports',
    title   : 'menu.reports.title',
    type    : 'group',
    icon    : 'heroicons_outline:home',
    children: [
        {
            id: 'activities.reports',
            title: 'module.bill.issued',
            type: 'basic',
            icon: 'heroicons_outline:inbox',
            link: '/bill/issued'
        },
        {
            id: 'activities.reports',
            title: 'module.bill.charged',
            type: 'basic',
            icon: 'heroicons_outline:inbox-in',
            link: '/bill/charged'
        },
        {
            id: 'activities.reports',
            title: 'module.bill.quarterlyIva',
            type: 'basic',
            icon: 'heroicons_outline:chart-bar',
            link: '/bill/quarterlyIva'
        }
    ]
}
