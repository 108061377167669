
export enum BillPanel {
    DATOS="general"
}

export enum ProviderSideBarElements {
    WRAPPER="fuse-vertical-navigation-item-wrapper",
    TITLE="fuse-vertical-navigation-item-title"
}

export enum BillQuarterOptions {
    Trimestre1 = 1,
    Trimestre2 = 2,
    Trimestre3 = 3,
    Trimestre4 = 4
}

export enum BillStateOptions {
    PENDIENTE = "Pendiente",
    COBRADA = "Cobrada"
}
