
export enum ProviderPanel {
    DATOS="general",
    CONTRACT="contract",
    COMUNICATION="communication",
}

export enum ProviderStatus {
    HABLANDO="Hablando",
    RESERVA="Reserva",
    CONFIRMADO="Confirmado",
    CANCELADO="Cancelado",
    LIBERADO="Liberado"
}

export enum ProviderSideBarElements {
    WRAPPER="fuse-vertical-navigation-item-wrapper",
    TITLE="fuse-vertical-navigation-item-title"
}

export enum PaymentTypeOptions {
    TELEFONO="Teléfono",
    WHATSAPP="WhatsApp",
    EMAIL="Email",
    OTROS="Otros"
}

export enum ProviderType {
    PERSONA_JURIDICA = "Persona Jurídica",
    PERSONA_FISICA = "Persona Física"
}
