<div class="modal-container">
    <div class="modal-title">
        {{ item.name || 'module.calendar.modalTitle' | transloco }}
    </div>
    <div mat-dialog-content class="modal-content-container">
        <div class="calendar-event-container" *ngFor="let attr of attrs">
            <ng-template [ngIf]="item[attr].length > 0">
                <div class="calendar-event-label">{{ module.length > 0 ? ('module.' + module + '.' + attr | transloco) : attr }}</div>
                <div class="calendar-event-content">{{ item[attr] }}</div>
            </ng-template>
        </div>
    </div>
    <div mat-dialog-actions class="justify-center">
        <button [disabled]="redirecting" color="primary" mat-raised-button type="button" (click)="redirect()">
            {{ "common.redirect" | transloco }}
        </button>
        <button color="warn" mat-raised-button type="button" (click)="close()">
            {{ "common.close" | transloco }}
        </button>
    </div>
</div>