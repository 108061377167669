import { FuseNavigationItem } from "../../../../@fuse/components/navigation";

export const adminItem: FuseNavigationItem = {
    id      : 'admin',
    title   : 'menu.admin.title',
    type    : 'group',
    icon    : 'mat_outline:settings',
    children:[
        {
            id   : 'settings.admin',
            title: 'module.settings.menu',
            type : 'basic',
            icon : 'heroicons_outline:cog',
            link : '/settings'
        }
    ]
};
