import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'input-filter-add',
    templateUrl: './input-filter-add.component.html',
    styleUrls: ['./input-filter-add.component.scss']
})
export class InputFilterAddComponent implements OnInit, OnChanges {
    @Input() formGroup: UntypedFormGroup;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() icon: string = '';
    @Input() items: any[] = [];
    @Input() title: string = '';
    @Input() title2: string = '';
    @Input() valueKey: string = '';
    @Input() value: string = '';
    @Input() action($event: any): void {}
    @Input() required: boolean = false;
    @Input() showButton: boolean = true;
    @Input() formControlSelect: string;
    @Input() formControlFilter: string;

    itemsAux: any[] = [];
    titleAux: string = '';

    constructor(private matDialog: MatDialog) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.itemsAux = this.items;
        }
    }

    ngOnInit(): void {
        this.titleAux = this.title;
        this.formGroup.addControl(this.formControlSelect, new FormControl(this.value, this.required ? Validators.required : null));
        this.formGroup.addControl(this.formControlFilter, new FormControl());
    }

    filter(event) {
        const filterValue = event.target.value.toLowerCase();
        if (!filterValue) this.itemsAux = this.items;
        this.itemsAux = this.items.filter((item) => item[this.titleAux].toLowerCase().includes(filterValue));
    }
}
