<div [formGroup]="formGroup">
    <mat-form-field class="mat-form-field-container">
        <mat-label>{{ label }}</mat-label>
        <mat-select [placeholder]="placeholder" [formControlName]="formControlSelect">
            <input class="mat-mdc-option input-select-filter" (keyup)="filter($event)" matInput type="text" [formControlName]="formControlFilter">
            <mat-option>{{ 'common.chooseSelect' | transloco }}</mat-option>
            <mat-option *ngFor="let item of itemsAux" [value]="(valueKey ? item[valueKey] : item)">
                {{ ( title ? item[title] : item ) }}{{ ( title2 && item[title2] != null ? " " + item[title2] : "" ) }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get(formControlSelect).hasError('required')">
            {{ "common.error.required" | transloco }}
        </mat-error>
        <mat-icon *ngIf="icon.length > 0" class="icon-size-5" [svgIcon]="icon" matPrefix></mat-icon>
        <button *ngIf="showButton" mat-icon-button (click)="action($event)" type="button" matSuffix>
            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:plus-circle"></mat-icon>
        </button>
    </mat-form-field>
</div>
