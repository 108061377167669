import { environment } from "../../../environments/environment";

export class UriUtils {

    static  buildUrl(url: string, ...segments: string[]): string {
        return UriUtils.appendParts(environment.serverUrl, url, ...segments);
    }

    static appendParts(host: string, ...parts: string[]) {
        let builtUrl = host;

        if (builtUrl.endsWith('/')) {
            builtUrl = builtUrl.slice(0, -1);
        }

        parts.forEach((part) => {
            if (!part.startsWith('/')) {
                part = '/' + part;
            }

            builtUrl += part;
        });

        return builtUrl;
    }
}
