/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'activities',
        title   : 'menu.bookingManagement.title',
        subtitle: 'menu.bookingManagement.subtitle',
        type    : 'group',
        icon    : 'heroicons_outline:sparkles',
        children:[
            {
                id   : 'activities.events',
                title: 'module.provider.menu',
                type : 'basic',
                icon : 'heroicons_outline:collection',
                link : '/provider'
            }
        ]
    },
    {
        id      : 'managements',
        title   : 'menu.management.title',
        subtitle: 'menu.management.subtitle',
        type    : 'group',
        icon    : 'heroicons_outline:adjustments',
        children: [
            {
                id   : 'managements.agent',
                title: 'module.agent.menu',
                type : 'basic',
                icon : 'mat_outline:work_outline',
                link : '/agent'
            },
            {
                id   : 'managements.artist',
                title: 'module.artist.menu',
                type : 'basic',
                icon : 'mat_outline:stars',
                link : '/artist'
            },
            {
                id   : 'managements.company',
                title: 'module.company.menu',
                type : 'basic',
                icon : 'mat_outline:business',
                link : '/company'
            },
            {
                id   : 'managements.contact',
                title: 'module.contact.menu',
                type : 'basic',
                icon : 'mat_outline:person_add',
                link : '/contact'
            },
            {
                id   : 'managements.enclosure',
                title: 'module.enclosure.menu',
                type : 'basic',
                icon : 'mat_outline:location_on',
                link : '/enclosure'
            }
        ]
    },
    {
        id      : 'dashboards',
        title   : 'menu.dashboards.title',
        subtitle: 'menu.dashboards.subtitle',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children:[
            {
                id   : 'dashboards.analytics',
                title: 'module.analytics.menu',
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/dashboards/analytics'
            },
            {
                id   : 'dashboards.invoicingReport',
                title: 'module.invoicingReport.menu',
                type : 'basic',
                icon : 'heroicons_outline:cash',
                link : '/dashboards/finance'
            },
            {
                id   : 'dashboards.artistReport',
                title: 'module.artistReport.menu',
                type : 'basic',
                icon : 'heroicons_outline:document-report',
                link : '/dashboards/report'
            },
        ]
    },
];

export const defaultNavigation2: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
