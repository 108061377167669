import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StateService, StateType } from '../../../../core/services';

@Component({
    selector: 'app-calendar-modal',
    templateUrl: './calendar-modal.component.html',
    styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent {
    item: any;
    redirecting: boolean = false;
    url: string = '';
    attrs: any[] = [];
    module: string = '';
    stateType: StateType = null;
    pageIndex: number = 0;

    orderedAttrs = [
        'number',
        'date',
        'name',
        'customer',
        'amountTotal',
        'status',
        'dateInit',
        'dateEnd',
        'income',
        'address',
    ];

    constructor(
        public dialogRef: MatDialogRef<CalendarModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly _router: Router,
        private _stateService: StateService
    ) {
        this.item = data.item;
        this.stateType = data.stateType ?? null;
        this.pageIndex = data.pageIndex ?? 0;

        if (this.item) {
            this.attrs = this.orderedAttrs.filter((attr) => attr in this.item);
            this.url = this.item.urlRedirect;
            this.module = this.item.module;
        }
    }

    redirect(): void {
        if (!this.url) {
            return;
        }

        const pageIndex =
            this.stateType === StateType.EVENT_CALENDAR_STATE
                ? '/calendars/project-calendar'
                : this.stateType === StateType.PAYMENT_CALENDAR_STATE
                ? '/calendars/project-calendar'
                : this.stateType === StateType.EVENT_MAP_STATE
                ? '/provider/location-map'
                : null;

        this._stateService.setState({
            type: StateType.PROVIDER_DETAIL_STATE,
            pageIndex: pageIndex
        });

        if (this.stateType) this._stateService.setState({ type: this.stateType, lastIdClicked: this.item.id, pageIndex: this.pageIndex });
        this.redirecting = true;
        this._router.navigate([this.url]);
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }
}
