export enum ProjectPanel {
    DATOS="general",
    TAREAS="task"
}

export enum ProviderSideBarElements {
    WRAPPER="fuse-vertical-navigation-item-wrapper",
    TITLE="fuse-vertical-navigation-item-title"
}

export enum ProjectStatusOptions {
    PENDIENTE="Pendiente",
    EN_EJECUCION="En ejecución",
    FINALIZADO="Finalizado",
    CANCELADO="Cancelado"
}

export enum ProviderType {
    PERSONA_JURIDICA = "Persona Jurídica",
    PERSONA_FISICA = "Persona Física"
}
