/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { modulesItems } from './items/modules.item';
import { reportsItems } from './items/reports.item';
import { calendarsItems } from './items/calendars.item';
import { mapsItems } from './items/maps.item';
import { ownerItem } from './items/owner.item';
import { adminItem } from './items/admin.item';

export const basicNavigation: FuseNavigationItem[] = [
    modulesItems,
];

export const fullNavigation: FuseNavigationItem[] = [
    modulesItems,
    reportsItems,
    mapsItems,
    calendarsItems,
    ownerItem
];

export const adminNavigation: FuseNavigationItem[] = [
    modulesItems,
    reportsItems,
    mapsItems,
    calendarsItems,
    adminItem
];

export const defaultNavigation: FuseNavigationItem[] = [
    modulesItems
];

export const defaultNavigation2: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
